@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NeueMontreal";
  src: local("NeueMontreal"),
    url("./assets/fonts/neue-montreal/NeueMontreal-Regular.otf")
      format("opentype");
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "NeueMontreal", sans-serif;
  font-size: 15px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.carousel {
  display: inline-flex;
  overflow-x: hidden;
  /*  scroll snap is a great feature which will center the image on snap on touch screen devices  */
  scroll-snap-type: x mandatory;
  /* all below will hide the scrollbar on all browsers.    */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.navbar {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}